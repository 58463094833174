import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import AboutConstruction from "images/construction/about_me_construction1.jpg";
import AboutConstruction2 from "images/construction/about_me_construction2.jpg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About JWC Construction Inc</Subheading>}
        heading="We want to take your project from start to finish."
        buttonRounded={false}
        primaryButtonText="See Photos"
        primaryButtonUrl="/photos"
        imageSrc={AboutConstruction}
        description={`
        We're a dedicated team of construction professionals committed to delivering 
        exceptional results. With years of experience, we specialize in countless areas of expertise. 
        Our focus is on quality, efficiency, and client satisfaction.
        `}
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We cater to your needs and adapt accordingly."
        buttonRounded={false}
        description={`
        What sets us apart is our unwavering commitment to quality, attention to detail, 
        and transparent communication. Our experienced team is passionate about building 
        lasting relationships with our clients, ensuring their complete satisfaction 
        throughout the entire construction process.
        `}
        primaryButtonText="Contact Us"
        primaryButtonUrl="/contact"
        imageSrc={AboutConstruction2}
        textOnLeft={false}
      />
      {/* <Features
        // subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
