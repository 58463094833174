import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">About</NavLink>
      <NavLink href="/photos">Photos</NavLink>
      {/* <NavLink href="#">
        About 
      </NavLink> */}
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/contact">
    //     Hire Us
    //   </PrimaryLink>
    // </NavLinks>
  ];

  return (
    <AnimationRevealPage>
      <StyledHeader links={navLinks} />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Direct Quotes",
            description: (
              <>
                <Phone>Phone: (561)-255-1660</Phone>
                <Email>Email: waltercabrera1520@gmail.com</Email>
                <Address>
                  <AddressLine>801 North Pkwy Suite 51</AddressLine>
                  <AddressLine>West Palm Beach, FL. 33407</AddressLine>
                </Address>
              </>
            ),
          },
          {
            title: "Main Office",
            description: (
              <>
                <Phone>Phone: (728)-900-1010</Phone>
                <Email>Email: jwc.usacontractors@gmail.com</Email>
                <Address>
                  <AddressLine>801 North Pkwy Suite 51</AddressLine>
                  <AddressLine>West Palm Beach, FL. 33407</AddressLine>
                </Address>
              </>
            ),
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
